{
  "name": "ezyvet-react-ui",
  "version": "5.33.0",
  "private": true,
  "description": "ezyVet React Component Library",
  "main": "dist/index.js",
  "types": "dist/index.d.ts",
  "files": [
    "dist/**/*"
  ],
  "scripts": {
    "build": "tsc",
    "lint": "eslint -c ./.eslintrc.json .",
    "lint-staged": "lint-staged",
    "prepare": "npm run build",
    "storybook": "storybook dev -p 6006",
    "build-storybook": "storybook build",
    "test": " jest --config ./jest.config.js --watch",
    "test-coverage": " jest --config ./jest.config.js --coverage --runInBand"
  },
  "pre-commit": [
    "lint-staged"
  ],
  "eslintConfig": {
    "extends": "react-app"
  },
  "repository": {
    "type": "git",
    "url": "git@gitlab.com:ezyvet/dependencies/react-ui.git"
  },
  "author": "ezyVet",
  "license": "ISC",
  "devDependencies": {
    "@babel/plugin-transform-runtime": "^7.10.1",
    "@babel/preset-env": "^7.10.2",
    "@babel/preset-react": "^7.10.1",
    "@storybook/addon-essentials": "^7.0.10",
    "@storybook/addon-interactions": "^7.0.10",
    "@storybook/addon-links": "^7.0.10",
    "@storybook/blocks": "^7.0.10",
    "@storybook/react": "^7.0.10",
    "@storybook/react-vite": "^7.0.10",
    "@storybook/testing-library": "^0.1.0",
    "@testing-library/jest-dom": "^5.10.0",
    "@testing-library/react": "^10.4.8",
    "@testing-library/user-event": "^13.1.9",
    "@types/jest": "^26.0.0",
    "@types/node": "^14.0.13",
    "@types/react": "^16.9.36",
    "@types/react-dom": "^16.9.8",
    "@types/styled-components": "^5.1.9",
    "@typescript-eslint/eslint-plugin": "^5.59.5",
    "@typescript-eslint/parser": "^5.59.5",
    "babel-loader": "^8.1.0",
    "eslint": "^7.32.0",
    "eslint-config-airbnb": "^18.2.1",
    "eslint-config-airbnb-typescript": "^12.3.1",
    "eslint-plugin-import": "^2.21.2",
    "eslint-plugin-jsx-a11y": "^6.2.3",
    "eslint-plugin-prettier": "^3.4.0",
    "eslint-plugin-react": "^7.20.0",
    "eslint-plugin-react-hooks": "^4.0.4",
    "eslint-plugin-storybook": "^0.6.12",
    "husky": "^4.2.5",
    "jest": "^26.0.1",
    "jest-css-modules": "^2.1.0",
    "jest-dom": "^4.0.0",
    "jest-styled-components": "^7.0.4",
    "lint-staged": "^10.2.9",
    "pre-commit": "^1.2.2",
    "prettier": "^2.2.1",
    "prop-types": "^15.8.1",
    "react": "^16.13.1",
    "react-dom": "^16.13.1",
    "storybook": "^7.0.10",
    "ts-jest": "^26.1.0",
    "ts-loader": "^7.0.5",
    "typescript": "^4.1.5"
  },
  "dependencies": {
    "@tanstack/table-core": "^8.13.0",
    "ezyvet-fe-helpers": "git+ssh://git@git.ezyvet.com/ezyvet/dependencies/fe-helpers.git#v0.15.0",
    "react-icons": "^3.10.0",
    "styled-components": "^5.3.0"
  }
}
